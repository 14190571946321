import React from 'react'
import './Innovation.css'
import backdropimage from './backdrop.png'

function Innovation() {
  return (
    <div className="parallax">
      <div className="parallax-content">
        <h1 className="heading">Embracing Technology: Committed to Innovation.</h1>
        <p className="description">Class IT is a leading IT telecommunications company, driven by unwavering dedication to innovation, revolutionizing the industry with cutting-edge solutions.</p>
      </div>
    </div>
  )
}

export default Innovation
