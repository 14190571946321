import React from 'react';
import './AboutUsMain.css';
import image from './image_aboutUs.jpg'

function AboutUsMain() {
    return (
        <div className="about-us-main">
            <div className="about-us-content">
                <h1>Who Are We?</h1>
                <p>As trailblazing pioneers in business Voice over IP (VoIP) phone services, we have redefined the way top-notch value is delivered to our clients. Our approach focuses on providing unparalleled quality, cost-effective, and reliable services at the most competitive prices.

In today's world, where email, messaging, and video conferencing have become prevalent, voice communications remain an essential cornerstone for businesses worldwide.</p>
            </div>
            <div className="about-us-image">
                <img src={image} alt="About us"/>
            </div>
        </div>
    );
}

export default AboutUsMain;
