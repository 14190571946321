import './App.css';
import AboutUs from './Components/AboutUs/AboutUs';
import AboutUsMain from './Components/AboutUsMain/AboutUsMain';
import Hero from './Components/Hero/Hero';
import Innovation from './Components/Innovation/Innovation';
import Navbar from './Components/Navbar/Navbar';
import WhatWeDo from './Components/WhatWeDo/WhatWeDo';

function App() {
  return (
    <div className="App">
      <Navbar/>
      <Hero/>
      <AboutUs/>
      <AboutUsMain/>
      <Innovation/>
      <WhatWeDo/>
    </div>
  );
}

export default App;
