import React, { useState, useEffect } from 'react';
import './Hero.css';
import video from './videoloop.mp4';
import { data } from './HeroData.js';
import image_logo from '../Navbar/classit.png'

function Hero() {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      document.querySelector('.hero-content').style.opacity = 0; // Fade-out the content
      setTimeout(() => {
        setIndex((prevIndex) => (prevIndex + 1) % data.length);
        document.querySelector('.hero-content').style.opacity = 1; // Fade-in with new content
      }, 1000); // Wait for 1s to let the fade-out transition complete
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="hero">
      <div className="transparency-layer" />
      <video autoPlay loop muted className="background-video">
        <source src={video} type="video/mp4" />
      </video>
      <div className='center_Logo'>
      <img src={image_logo} className='img_logo'/>
      <div className="hero-content">
        <div className="card">
            
          <h1 className='text_header'>{data[index].heading}</h1>
          <h2 className='text_subheader'>{data[index].subheading}</h2>
        </div>
      </div>
      </div>
    </div>
  );
}

export default Hero;
