import React from "react";
import { MdRecordVoiceOver } from "react-icons/md";
import { AiFillDatabase } from "react-icons/ai";
import { GiMeshNetwork } from "react-icons/gi";
import { CgComponents } from "react-icons/cg";
import "./WhatWeDo.css";

function WhatWeDo() {
  const sections = [
    {
      title: "Voice Solutions",
      icon: <MdRecordVoiceOver size={50} />,
      description:
        "We enhance existing systems or develop custom designed solutions to suit your exact needs.",
    },
    {
      title: "Data Solutions",
      icon: <AiFillDatabase size={50} />,
      description:
        "We establish environments that support long-term cost-effective broadband deployments.",
    },
    {
      title: "Network Solutions",
      icon: <GiMeshNetwork size={50} />,
      description:
        "We strategically create a competitive broadband environment for all business sectors.",
    },
  ];

  const listItems = [
    "Computer Networking & Cabling",
    "Fibre Optic Solutions – Single/Multi-Mode Fibre",
    "Splicing and OTDR Testing",
    "Server Room Construction & Maintenance",
    "CCTV Cameras and Access Control",
    "UPS Backups",
    "Wireless Solutions",
    "VOIP (Voice Over IP) Solutions",
    "Internet – Fibre, DSL, Wireless, Microwave, Satellite",
    "IT Support",
  ];

  return (
    <div className="whatWeDo">
      <div className="heading_wrapper">
        <div className="heading_section">What We Do</div>
        <div className="subheading_section">
          As a telecommunications solutions company, our primary goal is to
          continuously explore and discover innovative avenues for system
          integration within our cutting-edge communication network. By doing
          so, we aim to maximize cost efficiency and provide optimal savings to
          our valued customers in our next-generation solutions.
        </div>
      </div>
      <div className="section-cards">
        {sections.map((section, index) => (
          <div className="section-card" key={index}>
            <div className="icon_wwd">{section.icon}</div>
            <h2>{section.title}</h2>
            <p>{section.description}</p>
          </div>
        ))}
      </div>
      <div className="list-items">
        <ul>
          {listItems
            .slice(0, Math.ceil(listItems.length / 2))
            .map((item, index) => (
              <li key={index}>
                <span className="list-icon">
                  <CgComponents />
                </span>
                {item}
              </li>
            ))}
        </ul>
        <ul>
          {listItems
            .slice(Math.ceil(listItems.length / 2))
            .map((item, index) => (
              <li key={index}>
                <span className="list-icon">
                  <CgComponents />
                </span>
                {item}
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
}

export default WhatWeDo;
