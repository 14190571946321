import React, { useState } from 'react';
import image from './classit.png';
import './Navbar.css';

function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setMenuOpen((prevState) => !prevState);
  };

  return (
    <div>
      <div className={`navT ${menuOpen ? 'active' : ''}`} onClick={handleMenuToggle}>
        <div className="icon">
        
        </div>
        <img src={image} className='image_logo' alt='classit'/>
      </div>

      <div id="menu" className={menuOpen ? 'open' : ''}>
        <ul>
          <li>
            <a>Hom<span className="space">e</span></a>
          </li>
          <li>
            <a>Abou<span className="space">t</span></a>
          </li>
          <li>
            <a>Wor<span className="space">k</span></a>
          </li>
          <li>
            <a>Blo<span className="space">g</span></a>
          </li>
          <li>
            <a>Contac<span className="space">t</span></a>
          </li>
        </ul>
      </div>
      
    </div>
  );
}

export default Navbar;
