export const data = [
    {
      heading: 'Fostering Forward Thinking',
      subheading: 'Welcome to the New Frontier in Telecommunication and IT',
    },
    {
      heading: 'NO COMMUNICATION LIMITS',
      subheading: 'Bridging the Gap with Innovative Technology',
    },
    {
      heading: 'EMPOWERING CONNECTIONS',
      subheading: 'Strengthening Interactions: Building Bridges Together',
    },
    {
      heading: 'BOLDLY DEFINING THE FUTURE',
      subheading: 'Join the Revolution in High-Speed Communication',
    },
    {
      heading: 'BREAKING BARRIERS',
      subheading: 'Redefining Borders in the Digital Age',
    },
    {
      heading: 'CLOUD UNLEASHED',
      subheading: 'Experience Unsurpassed Freedom and Flexibility',
    },
    {
      heading: 'INNOVATION AT SCALE',
      subheading: 'Revolutionizing Efficiency in IT Solutions',
    },
    {
      heading: 'SECURITY REIMAGINED',
      subheading: 'Fortifying the Future of Telecommunications',
    },
    {
      heading: 'THE POWER OF AI',
      subheading: 'Transforming Telecommunication with Smart Technology',
    },
    {
      heading: 'SUSTAINABLE SOLUTIONS',
      subheading: 'Green Tech for a Brighter Tomorrow',
    },
  
  ];
